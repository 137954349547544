import { render, staticRenderFns } from "./PublicToolbar.vue?vue&type=template&id=8fa74b50"
import script from "./PublicToolbar.vue?vue&type=script&lang=js"
export * from "./PublicToolbar.vue?vue&type=script&lang=js"
import style0 from "./PublicToolbar.vue?vue&type=style&index=0&id=8fa74b50&prod&lang=scss"


/* normalize component */
import normalizer from "!../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports