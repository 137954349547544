<template>
  <div>
    <public-toolbar />

    <v-main>
      <router-view :key="`children/${$route.path}`" />
    </v-main>

    <public-footer />
  </div>
</template>

<script>
import PublicFooter from "../components/PublicFooter.vue";
import PublicToolbar from "../components/PublicToolbar.vue";

export default {
  name: "Index",

  components: {
    PublicToolbar,
    PublicFooter
  }
};
</script>

<style lang="scss" scoped>
.v-main {
  padding-top: 132px !important;

  @media only screen and (max-width: 959px) {
    padding-top: 56px !important;
  }
}

.container {
  max-width: 1185px;
}
</style>
