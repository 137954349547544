<template>
  <v-footer class="mt-12" style="border-top: 1px solid #CCC">
    <v-container>
      <v-row justify="space-between" class="px-2" align="center">
        <!-- <div class="d-flex">
          <span class="mr-2 pt-3" style="height: 100%">Siga nossa nossa página:</span>
          <a href="https://www.instagram.com/brinquedotecapedagogica/" target="_blank">
            <img src="@/assets/instagram-icon.png" alt="Instagram" width="40" />
          </a>
        </div>
        <span class="pr-12" :class="{ 'pb-3': !isDesktop }"
          >Contato: <a href="tel:+99999">(31) 99999</a></span
        > -->
        <img src="@/assets/site_seguro.png" alt="Site seguro" width="150" />
        <span class="pr-12">Erótica BH - Venda de produtos sex shop</span>
        <span class="pr-12">Belo Horizonte - MG</span>
      </v-row>
    </v-container>
  </v-footer>
</template>

<script>
export default {
  name: "PublicFooter"
};
</script>
