<template>
  <div id="container-public-toolbar">
    <v-toolbar color="secondary">
      <v-container>
        <v-row>
          <v-col cols="2" :class="{ 'pl-0': !isDesktop }">
            <div id="brand">
              <a href="/">
                <v-img
                  v-if="isDesktop"
                  id="logo"
                  contain
                  src="@/assets/logo.jpeg"
                  alt="Erótica BH"
                />
                <v-img
                  v-else
                  id="logo_mobile"
                  contain
                  src="@/assets/logo_mobile.png"
                  alt="Erótica BH"
                />
              </a>
              <!-- <a
                v-if="isDesktop"
                href="/"
                class="ml-5 text-h6 mt-0"
                style="line-height: 1.2; color: red !important"
                >Erótica<br />&nbsp;&nbsp;&nbsp;BH</a
              > -->
            </div>
          </v-col>
          <v-col cols="8" :class="{ 'pr-0': !isDesktop, boxSearchDesktop: isDesktop }">
            <v-text-field
              v-model="search"
              color="white"
              autocomplete="off"
              solo
              hide-details
              dense
              placeholder="O que você procura?"
              class="py-0"
              :class="isDesktop ? 'mt-2' : 'mt-1'"
              @keyup.enter="paginaBusca"
            >
              <template slot="append-outer">
                <v-btn
                  color="black"
                  @click="paginaBusca"
                  style="margin-top: -7px; margin-left: -46px; height: 38px; border-top-left-radius: 0; border-bottom-left-radius: 0; min-width: 0; padding: 7px"
                >
                  <v-icon color="white">mdi-magnify</v-icon>
                </v-btn>
              </template>
            </v-text-field>
          </v-col>
          <v-col cols="2" :class="{ 'pr-0': !isDesktop }">
            <div class="d-flex justify-end">
              <a
                v-if="isDesktop"
                :href="linkWhatsApp(mensagem)"
                target="_blank"
                class="ml-8 mt-2"
                title="Whatsapp"
              >
                <v-icon large color="white">mdi-whatsapp</v-icon>
              </a>
              <!-- <a
                v-if="isDesktop"
                href="https://www.instagram.com/brinquedotecapedagogica/"
                target="_blank"
                class="ml-8 mt-2"
                title="Instagram"
              >
                <v-icon large color="white">mdi-instagram</v-icon>
              </a> -->
              <v-app-bar-nav-icon
                v-if="!isDesktop"
                large
                color="white"
                @click.stop="drawer = !drawer"
              />
            </div>
          </v-col>
        </v-row>
      </v-container>
      <template #extension v-if="isDesktop">
        <v-container class="py-0">
          <hr style="border: 1px solid #aaa" />
          <nav class="d-flex justify-center">
            <a
              v-for="(categoria, iCategoria) in categorias"
              :key="iCategoria"
              :id="`menu${iCategoria}`"
              class="mr-6 py-2"
              :href="
                categoria.link == 'marcas'
                  ? 'javascript: void(0)'
                  : `/#/categoria/${categoria.link}`
              "
            >
              <b>{{ categoria.label }}</b>
              <v-menu
                v-if="
                  categoria.sub.length &&
                    categoria.link != 'sex-shop_produtos-eroticos-mais-vendidos'
                "
                open-on-hover
                offset-y
                max-width="100%"
                :activator="`#menu${iCategoria}`"
              >
                <v-list dense class="container-public-toolbar__submenu-list">
                  <v-list-item
                    tag="a"
                    v-for="subcategoria in categoria.sub"
                    :key="subcategoria.link"
                    :href="`/#/categoria/${subcategoria.link}`"
                  >
                    {{ subcategoria.label }}
                  </v-list-item>
                </v-list>
              </v-menu>
            </a>
          </nav>
        </v-container>
      </template>
    </v-toolbar>
    <v-navigation-drawer
      v-if="!isDesktop"
      v-model="drawer"
      fixed
      right
      temporary
      color="primary"
      app
    >
      <v-expansion-panels accordion>
        <template v-for="categoria in categorias">
          <v-expansion-panel
            v-if="
              categoria.sub.length && categoria.link != 'sex-shop_produtos-eroticos-mais-vendidos'
            "
            :key="'group_' + categoria.link"
          >
            <v-expansion-panel-header>{{ categoria.label }}</v-expansion-panel-header>
            <v-expansion-panel-content style="background-color: #f12b3a;">
              <v-list-item
                v-if="categoria.link != 'marcas'"
                tag="a"
                :href="`/#/categoria/${categoria.link}`"
                style="background-color: #e11b2a;"
              >
                Ver todos
              </v-list-item>
              <v-list-item
                v-for="subcategoria in categoria.sub"
                :key="subcategoria.link"
                tag="a"
                :href="`/#/categoria/${subcategoria.link}`"
              >
                {{ subcategoria.label }}
              </v-list-item>
            </v-expansion-panel-content>
          </v-expansion-panel>

          <v-list-item
            v-else
            :key="categoria.link"
            tag="a"
            :href="`/#/categoria/${categoria.link}`"
          >
            {{ categoria.label }}
          </v-list-item>
        </template>
      </v-expansion-panels>
    </v-navigation-drawer>
    <div class="box-whatsapp pa-2">
      <a :href="linkWhatsApp(mensagem)" target="_blank">
        <v-icon large color="white">mdi-whatsapp</v-icon>
      </a>
    </div>
  </div>
</template>

<script>
import CategoriaRepository from "@/repositories/CategoriaRepository";

export default {
  name: "PublicToolbar",

  data: () => ({
    categorias: [],
    search: "",
    drawer: false,
    mensagem: "Olá, quero saber mais informações sobre a Erótica BH"
  }),

  async created() {
    this.categorias = await CategoriaRepository.buscarTodas();
  },

  methods: {
    paginaBusca() {
      if (!this.search) {
        return;
      }

      window.location.href = `/#/busca/${this.search}`;
    }
  }
};
</script>

<style lang="scss">
#container-public-toolbar {
  header {
    z-index: 7;
    position: fixed !important;
    top: 0;
    left: 0;
    right: 0;

    .v-toolbar__extension {
      position: static;

      .container {
        padding-left: 120px;
      }
    }

    #brand {
      z-index: 1000000000000000000000;
      position: absolute;

      #logo {
        border-radius: 50%;
        width: 98px;
      }

      #logo_mobile {
        border-radius: 50%;
        width: 46px;
      }
    }

    .boxSearchDesktop {
      padding-left: 120px;
    }

    a {
      color: white !important;
      text-decoration: none;
    }
  }

  .v-navigation-drawer {
    top: 56px !important;
    width: 300px !important;

    a {
      color: white !important;
      font-size: 15px;
      font-weight: 500;
      text-decoration: none;
    }

    .v-expansion-panel {
      color: white !important;
      font-size: 15px;
      font-weight: 500;
      background-color: transparent !important;

      .v-expansion-panel-content__wrap {
        padding: 0;

        .v-list-item {
          padding-left: 35px;
        }
      }
    }
  }

  .box-whatsapp {
    background-color: #68d856;
    position: fixed;
    bottom: 75px;
    right: 15px;
    z-index: 6;
    border-radius: 50%;

    a {
      text-decoration: none;
    }
  }
}

.container-public-toolbar__submenu-list {
  padding: 0;
  display: grid;
  padding: 20px 10px 15px;
  grid-template-columns: 34% 33% 33%;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;

  a {
    white-space: nowrap;
    padding-right: 44px;
    text-decoration: none;
    color: #840610 !important;
  }

  a:hover {
    background-color: #840610;
    color: white !important;
  }
}
</style>
